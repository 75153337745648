import React from 'react';
import { motion } from 'framer-motion';
import { FaUser, FaRobot } from 'react-icons/fa';

const ChatMessage = ({ isUser, message, delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 20, x: isUser ? 20 : -20 }}
    animate={{ opacity: 1, y: 0, x: 0 }}
    transition={{ delay, duration: 0.5 }}
    className={`flex items-start gap-3 ${isUser ? 'flex-row-reverse' : ''} mb-6`}
  >
    <div className={`w-8 h-8 rounded-full flex items-center justify-center 
                    ${isUser ? 'bg-primary-500/20' : 'bg-accent-500/20'}`}>
      {isUser ? <FaUser className="text-primary-400" /> : <FaRobot className="text-accent-400" />}
    </div>
    <motion.div
      initial={{ scale: 0.95 }}
      animate={{ scale: 1 }}
      transition={{ delay: delay + 0.1, duration: 0.2 }}
      className={`max-w-[80%] rounded-2xl p-4 ${
        isUser 
          ? 'bg-primary-500/10 backdrop-blur-sm border border-primary-500/20' 
          : 'bg-accent-500/10 backdrop-blur-sm border border-accent-500/20'
      }`}
    >
      <p className="text-white/80 font-light">{message}</p>
    </motion.div>
  </motion.div>
);

const TypingIndicator = () => (
  <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
    className="flex items-center gap-1 p-2"
  >
    {[0, 1, 2].map((i) => (
      <motion.div
        key={i}
        className="w-2 h-2 bg-accent-500/50 rounded-full"
        animate={{ y: [-2, 2, -2] }}
        transition={{
          duration: 0.6,
          repeat: Infinity,
          delay: i * 0.2,
        }}
      />
    ))}
  </motion.div>
);

const VoiceMessage = ({ delay }) => (
  <motion.div
    initial={{ opacity: 0, y: 20, x: 20 }}
    animate={{ opacity: 1, y: 0, x: 0 }}
    transition={{ delay, duration: 0.5 }}
    className="flex flex-row-reverse items-start gap-3 mb-6"
  >
    <div className="w-8 h-8 rounded-full bg-primary-500/20 flex items-center justify-center">
      <FaUser className="text-primary-400" />
    </div>
    <motion.div
      initial={{ scale: 0.95 }}
      animate={{ scale: 1 }}
      transition={{ delay: delay + 0.1, duration: 0.2 }}
      className="max-w-[80%] rounded-2xl p-4 bg-primary-500/10 backdrop-blur-sm border border-primary-500/20"
    >
      <div className="flex items-center gap-4">
        {/* Play button */}
        <div className="w-8 h-8 rounded-full bg-primary-400/20 flex items-center justify-center">
          <motion.div
            animate={{ scale: [1, 0.9, 1] }}
            transition={{ duration: 1.5, repeat: Infinity }}
          >
            <svg className="w-4 h-4 text-primary-400" viewBox="0 0 24 24" fill="currentColor">
              <path d="M8 5v14l11-7z" />
            </svg>
          </motion.div>
        </div>

        <div className="flex-1 space-y-1">
          {/* Waveform */}
          <div className="h-6 flex items-center gap-[2px]">
            {[...Array(40)].map((_, i) => (
              <motion.div
                key={i}
                className="flex-1 bg-primary-400/60 rounded-full"
                initial={{ height: '20%' }}
                animate={{ 
                  height: ['20%', '90%', '40%', '70%', '20%'][i % 5] 
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  delay: i * 0.05
                }}
              />
            ))}
          </div>
          
          {/* Progress bar */}
          <div className="h-[3px] bg-primary-400/20 rounded-full">
            <motion.div 
              className="h-full w-0 bg-primary-400 rounded-full"
              animate={{ width: '100%' }}
              transition={{ duration: 10, repeat: Infinity }}
            />
          </div>
        </div>

        {/* Time and speed controls */}
        <div className="flex flex-col items-end gap-1">
          <span className="text-white/60 text-xs">7:12</span>
          <span className="text-primary-400 text-xs">1.5x</span>
        </div>
      </div>
    </motion.div>
  </motion.div>
);

const ChatDemo = () => {
  const conversation = [
    { isUser: true, message: "Hi! I need help with something.", delay: 0.5 },
    { isUser: false, message: "Hello! I'm here to help. What can I do for you?", delay: 1.5 },
    { 
      type: 'voice',
      delay: 2.5 
    },
    { 
      isUser: true, 
      message: "Could you summarize the main points from this voice message? It's about the latest developments in renewable energy.", 
      delay: 3.5 
    },
    { 
      isUser: false, 
      message: "Based on the voice message, here are the key points about renewable energy developments:\n\n" +
               "1. Solar efficiency increased by 25% with new panel technology\n" +
               "2. Wind farms now 40% more cost-effective than in 2020\n" +
               "3. Breakthrough in energy storage using sustainable materials\n" +
               "4. Government initiatives boosting adoption rates globally",
      delay: 4.5 
    },
  ];

  return (
    <div className="relative w-full max-w-2xl mx-auto">
      {/* Gradient background effect */}
      <div className="absolute -inset-4 bg-gradient-to-r from-primary-500/20 via-accent-500/20 to-primary-500/20 rounded-3xl blur-xl animate-gradient bg-300%"></div>
      
      {/* Chat container */}
      <div className="relative rounded-3xl border border-white/10 bg-black/40 backdrop-blur-xl p-8 overflow-hidden">
        {/* Chat header */}
        <div className="flex items-center gap-4 pb-6 mb-6 border-b border-white/10">
          <div className="w-10 h-10 rounded-full bg-accent-500/20 flex items-center justify-center">
            <FaRobot className="text-accent-400" />
          </div>
          <div>
            <h3 className="text-white/90 font-medium">Wapertu AI Assistant</h3>
            <span className="text-white/40 text-sm">Always online</span>
          </div>
          <div className="ml-auto flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-accent-400 animate-pulse"></div>
            <span className="text-accent-400 text-sm">Active</span>
          </div>
        </div>

        {/* Chat messages */}
        <div className="space-y-4">
          {conversation.map((msg, index) => (
            msg.type === 'voice' ? (
              <VoiceMessage key={index} delay={msg.delay} />
            ) : (
              <ChatMessage key={index} {...msg} />
            )
          ))}
          <TypingIndicator />
        </div>
      </div>
    </div>
  );
};

export default ChatDemo; 