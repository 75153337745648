import React from 'react';
import Hero from '../components/Hero';
import ServiceCard from '../components/ServiceCard';
import Testimonials from '../components/Testimonials';
import Partners from '../components/Partners';
import ChatbotGuide from '../components/ChatbotGuide';
import ImageSection from '../components/ImageSection';
import { FaRobot, FaImage, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import PrimaryButton from '../components/PrimaryButton';
import { Link } from 'react-router-dom';

const ProductCard = ({ icon: Icon, title, description, link }) => (
  <Link to={link}>
    <motion.div
      whileHover={{ y: -5 }}
      className="relative group cursor-pointer"
    >
      <div className="absolute -inset-2 bg-gradient-to-r from-primary-500/20 to-accent-500/20 rounded-xl blur-lg 
                    opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
      <div className="relative p-6 bg-white/5 border border-white/10 rounded-xl backdrop-blur-sm">
        <div className="w-12 h-12 bg-gradient-to-br from-primary-500 to-accent-500 rounded-lg flex items-center justify-center mb-4">
          <Icon className="text-white text-2xl" />
        </div>
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <p className="text-gray-400 mb-4">{description}</p>
        <span className="text-primary-400 group-hover:text-accent-400 transition-colors">
          Learn more →
        </span>
      </div>
    </motion.div>
  </Link>
);

const Home = () => {
  const services = [
    {
      title: "AI Chat Bot",
      description: "Intelligent conversations. Instant responses. Seamless experience.",
      icon: FaRobot,
      link: "/products/chat-bot",
      gradient: "from-blue-500/20 to-indigo-500/20"
    },
    {
      title: "Image Generator",
      description: "Transform words into art. Unparalleled quality. Limitless creativity.",
      icon: FaImage,
      link: "/products/image-generator",
      gradient: "from-purple-500/20 to-pink-500/20"
    },
    {
      title: "Secure Conversations",
      description: "Military-grade encryption. Complete privacy. Peace of mind.",
      icon: FaShieldAlt,
      price: "Free",
      gradient: "from-emerald-500/20 to-teal-500/20"
    }
  ];

  return (
    <div className="min-h-screen bg-secondary-900 text-white">
      <Hero />

      {/* AI Services Section */}
      <div className="container mx-auto px-6 py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-24"
        >
          <h2 className="text-5xl font-light mb-6 tracking-tight">
            Our
            <span className="bg-gradient-to-r from-primary-400/90 to-accent-400/90 text-transparent bg-clip-text font-normal">
              {" "}AI-Powered Services
            </span>
          </h2>
          <p className="text-gray-400/80 text-lg font-light tracking-wide max-w-xl mx-auto">
            Enhance your digital experience with our refined WhatsApp AI solutions.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-16">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <div className="group cursor-pointer relative h-full">
                {/* Gradient background with blur */}
                <div className={`absolute -inset-0.5 bg-gradient-to-r ${service.gradient} rounded-2xl blur opacity-0 
                              group-hover:opacity-100 transition duration-500`} />
                
                {/* Content container */}
                <div className="relative h-full p-8 bg-black/40 backdrop-blur-sm border border-white/5 rounded-2xl 
                            hover:border-white/10 transition duration-500 flex flex-col">
                  {/* Icon container */}
                  <div className="w-16 h-16 mb-8 relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                                rounded-xl transform rotate-6 transition-transform group-hover:rotate-12" />
                    <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-xl flex items-center justify-center">
                      <service.icon className="text-2xl text-white/80" />
                    </div>
                  </div>
                  
                  {/* Text content */}
                  <h3 className="text-2xl font-light mb-4 tracking-wide text-white/90">
                    {service.title}
                  </h3>
                  <p className="text-white/60 font-light leading-relaxed mb-8 text-sm tracking-wide">
                    {service.description}
                  </p>
                  
                  {/* Action link/price */}
                  <div className="mt-auto">
                    {service.link ? (
                      <Link 
                        to={service.link}
                        className="inline-flex items-center text-white/80 hover:text-white 
                                  transition-colors duration-300 text-sm tracking-wider"
                      >
                        Explore
                        <svg className="w-4 h-4 ml-2 transform group-hover:translate-x-1 transition-transform" 
                             fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                        </svg>
                      </Link>
                    ) : (
                      <span className="text-emerald-400/90 font-light tracking-wider text-sm">{service.price}</span>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Experience Section - Now third in order */}
      <section className="py-32 relative overflow-hidden">
        {/* Background Elements */}
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
        </div>

        <div className="container mx-auto px-6 relative">
          {/* Section Header with integrated content */}
          <div className="flex flex-col md:flex-row items-center gap-16 mb-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="w-full md:w-1/2"
            >
              <div className="inline-flex items-center gap-2 bg-white/5 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
                <div className="w-2 h-2 rounded-full bg-primary-400"></div>
                <span className="text-sm font-medium text-primary-200">Experience</span>
              </div>
              
              <h2 className="text-4xl md:text-5xl font-light mb-6">
                Experience the Future of
                <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
                  AI Communication
                </span>
              </h2>
              
              <p className="text-lg text-gray-400/80 font-light tracking-wide">
                Interact seamlessly with our AI bots on WhatsApp. Whether it's generating images or having meaningful conversations, we've got you covered.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="w-full md:w-1/2"
            >
              <div className="relative aspect-square rounded-2xl overflow-hidden bg-black/40 backdrop-blur-sm border border-white/10">
                {/* Chat Container */}
                <div className="absolute inset-0 p-6 flex flex-col">
                  {/* Chat Header */}
                  <div className="flex items-center gap-3 pb-4 border-b border-white/10">
                    <div className="w-3 h-3 rounded-full bg-primary-400"></div>
                    <span className="text-sm font-light text-white/70">AI Assistant</span>
                  </div>

                  {/* Chat Messages */}
                  <div className="flex-1 overflow-y-auto py-6 space-y-4">
                    {/* User Message */}
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      className="flex items-start justify-start"
                    >
                      <div className="bg-white/10 backdrop-blur-sm text-white/80 rounded-2xl rounded-tl-none p-4 max-w-[80%]
                                    border border-white/5">
                        <p className="text-sm font-light">Can you help me create an image of a futuristic cityscape at sunset?</p>
                      </div>
                    </motion.div>

                    {/* AI Response */}
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: 0.7 }}
                      className="flex items-start justify-end"
                    >
                      <div className="bg-gradient-to-r from-primary-500/20 to-accent-500/20 backdrop-blur-sm text-white/90 
                                    rounded-2xl rounded-tr-none p-4 max-w-[80%] border border-white/10">
                        <p className="text-sm font-light">I'll help you create that image. Would you like it in a cyberpunk style with neon accents, or a more clean, minimalist futuristic look?</p>
                      </div>
                    </motion.div>

                    {/* Typing Indicator */}
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: [0, 1, 0] }}
                      transition={{ duration: 1.5, repeat: Infinity }}
                      className="flex items-center gap-1 px-4"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400/60"></div>
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400/60"></div>
                      <div className="w-1.5 h-1.5 rounded-full bg-primary-400/60"></div>
                    </motion.div>
                  </div>

                  {/* Chat Input */}
                  <div className="pt-4 border-t border-white/10">
                    <div className="flex items-center gap-2 bg-white/5 rounded-xl p-3">
                      <div className="flex-1 h-6 bg-white/10 rounded-md"></div>
                      <div className="w-6 h-6 rounded-full bg-primary-400/20 flex items-center justify-center">
                        <svg className="w-3 h-3 text-primary-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 12h14M12 5l7 7-7 7" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent pointer-events-none"></div>
              </div>
            </motion.div>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12">
            {[
              {
                icon: FaRobot,
                title: "Intelligent AI",
                description: "Advanced natural language processing for human-like interactions",
                gradient: "from-blue-500/20 to-indigo-500/20"
              },
              {
                icon: FaImage,
                title: "Visual Creation",
                description: "Transform text into stunning visual masterpieces instantly",
                gradient: "from-purple-500/20 to-pink-500/20"
              },
              {
                icon: FaShieldAlt,
                title: "Secure Platform",
                description: "Enterprise-grade security with end-to-end encryption",
                gradient: "from-emerald-500/20 to-teal-500/20"
              },
              {
                icon: FaChartLine,
                title: "Smart Analytics",
                description: "Insights and patterns from your AI interactions",
                gradient: "from-amber-500/20 to-orange-500/20"
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="group relative"
              >
                {/* Gradient Background */}
                <div className={`absolute -inset-0.5 bg-gradient-to-r ${feature.gradient} rounded-2xl blur opacity-0 
                              group-hover:opacity-100 transition duration-500`} />
                
                {/* Content Container */}
                <div className="relative p-8 bg-black/40 backdrop-blur-sm border border-white/5 rounded-2xl 
                            hover:border-white/10 transition duration-500">
                  {/* Icon */}
                  <div className="w-14 h-14 mb-6 relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                                rounded-xl transform rotate-6 transition-transform group-hover:rotate-12" />
                    <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-xl 
                                flex items-center justify-center">
                      <feature.icon className="text-2xl text-white/80" />
                    </div>
                  </div>
                  
                  {/* Text Content */}
                  <h3 className="text-xl font-light mb-3 tracking-wide text-white/90">
                    {feature.title}
                  </h3>
                  <p className="text-white/60 font-light text-sm tracking-wide leading-relaxed">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Bottom CTA */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center mt-24"
          >
            <Link
              to="/get-started"
              className="group relative inline-flex items-center gap-2 bg-white/5 hover:bg-white/10 
                       backdrop-blur-sm px-8 py-4 rounded-xl border border-white/10 hover:border-white/20 
                       transition-all duration-300"
            >
              <span className="text-white/90 font-light tracking-wide">Explore Features</span>
              <svg 
                className="w-4 h-4 text-white/70 transform group-hover:translate-x-1 transition-transform" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Chatbot Guide Section */}
      <ChatbotGuide />

      {/* Additional Image Section */}
      <ImageSection
        title="Transform Ideas into Visual Masterpieces"
        description="Experience the future of image creation with our advanced AI. Generate stunning, photorealistic images or artistic masterpieces with simple text prompts through WhatsApp. From concept to creation in seconds."
        imageUrl="/Untitled design.svg"
        reverse={true}
      />

     

      {/* Testimonials Section */}
      <Testimonials />

      {/* Partners Section */}
      <Partners />

      {/* Products Section */}
      <section className="py-32 relative overflow-hidden">
        {/* Animated Background */}
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
        </div>

        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-24"
          >
            {/* Premium Badge */}
            <div className="inline-flex items-center gap-3 bg-white/5 backdrop-blur-md px-6 py-3 rounded-full mb-8
                         border border-white/10 hover:border-white/20 transition-all duration-500">
              <div className="w-2 h-2 rounded-full bg-primary-400 animate-pulse"></div>
              <span className="text-sm font-light tracking-wider text-white/80 uppercase">Our Solutions</span>
            </div>

            {/* Elegant Title */}
            <h2 className="text-5xl md:text-6xl font-extralight mb-8 tracking-tight">
              Experience Our
              <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                           animate-gradient bg-300% font-light">
                AI-Powered Products
              </span>
            </h2>
          </motion.div>

          {/* Products Grid */}
          <div className="grid md:grid-cols-2 gap-8 xl:gap-12">
            {[
              {
                icon: FaRobot,
                title: "AI Chat Bot",
                description: "Experience intelligent conversations with our advanced AI chat bot on WhatsApp.",
                link: "/products/chat-bot",
                gradient: "from-violet-500/20 via-fuchsia-500/20 to-pink-500/20"
              },
              {
                icon: FaImage,
                title: "Image Generator",
                description: "Create stunning visuals from text descriptions instantly on WhatsApp.",
                link: "/products/image-generator",
                gradient: "from-cyan-500/20 via-blue-500/20 to-indigo-500/20"
              }
            ].map((product, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="group relative"
              >
                {/* Animated Gradient Background */}
                <div className={`absolute -inset-[1px] bg-gradient-to-r ${product.gradient} rounded-3xl blur-lg opacity-0 
                              group-hover:opacity-100 transition-all duration-700 animate-pulse`}></div>
                
                {/* Glass Container */}
                <Link to={product.link} className="relative flex h-full bg-black/40 backdrop-blur-md border border-white/5 rounded-3xl p-10
                          hover:border-white/10 transition-all duration-700 flex-col">
                  {/* Icon Container */}
                  <div className="w-16 h-16 mb-8 relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                                rounded-2xl transform rotate-6 transition-transform group-hover:rotate-12" />
                    <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-2xl 
                                flex items-center justify-center">
                      <product.icon className="text-2xl text-white/80" />
                    </div>
                  </div>
                  
                  {/* Content */}
                  <h3 className="text-2xl font-light mb-4 tracking-wide text-white/90">
                    {product.title}
                  </h3>
                  <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed mb-8">
                    {product.description}
                  </p>
                  
                  {/* Action Link */}
                  <div className="mt-auto flex items-center gap-2 text-white/80 group-hover:text-white/90 transition-colors">
                    <span className="font-light tracking-wide">Explore</span>
                    <svg 
                      className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-24 bg-gradient-to-r from-primary-500 to-accent-600">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            Ready to Elevate Your WhatsApp Experience?
          </h2>
          <PrimaryButton href="#get-started">
            Get Started Now
          </PrimaryButton>
        </div>
      </section>
    </div>
  );
}

export default Home;