import React from 'react';
import { motion } from 'framer-motion';

const Partners = () => {
  const partners = [
    { 
      name: 'OpenAI', 
      logo: '/openai_20240427175829original_image_19.webp' 
    },
    { 
      name: 'BlackForest Labs', 
      logo: '/flux-logo.webp' 
    }
  ];

  // Duplicate the array to create seamless loop
  const duplicatedPartners = [...partners, ...partners];

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
      </div>

      <div className="container mx-auto px-6 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
            <div className="w-2 h-2 rounded-full bg-primary-400"></div>
            <span className="text-sm font-medium text-primary-200">Trusted Partners</span>
          </div>

          <h2 className="text-3xl font-bold text-white mb-4">
            Powered by Industry Leaders
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            We collaborate with the best in the industry to bring you cutting-edge AI technology
          </p>
        </motion.div>

        {/* Logo Slider */}
        <div className="relative w-full overflow-hidden">
          <div className="flex">
            <motion.div
              className="flex gap-24 items-center justify-center"
              animate={{
                x: [0, -100 * partners.length],
              }}
              transition={{
                x: {
                  duration: partners.length * 5,
                  repeat: Infinity,
                  ease: "linear"
                }
              }}
            >
              {duplicatedPartners.map((partner, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 relative group w-32"
                >
                  <img 
                    src={partner.logo} 
                    alt={`${partner.name} logo`} 
                    className="w-full h-12 object-contain opacity-50 
                             group-hover:opacity-100 transition-opacity duration-500"
                  />
                </div>
              ))}
            </motion.div>
          </div>
        </div>

        <div className="text-sm text-gray-400">
          This is a third-party service that uses WhatsApp's Business API. WhatsApp is a registered trademark of Meta Platforms, Inc.
        </div>
      </div>
    </section>
  );
};

export default Partners; 