import { motion } from 'framer-motion';
import { FaTrash, FaEnvelope, FaWhatsapp } from 'react-icons/fa';

const DataDeletion = () => {
  return (
    <div className="min-h-screen bg-secondary-900">
      <div className="relative pt-32 pb-20 overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
              <FaTrash className="text-primary-400" />
              <span className="text-sm font-medium text-white">Data Deletion</span>
            </div>

            <h1 className="text-4xl font-bold text-white mb-8">
              Data Deletion
              <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
                Request Process
              </span>
            </h1>

            <div className="space-y-8">
              <div className="bg-white/5 p-6 rounded-xl border border-white/10">
                <h2 className="text-xl text-white mb-4 flex items-center gap-2">
                  <FaEnvelope className="text-primary-400" />
                  Email Request
                </h2>
                <p className="text-white/70">
                  Send a deletion request to support@wapertu.com with:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2 text-white/70">
                  <li>Subject line: "Data Deletion Request"</li>
                  <li>Your registered email address</li>
                  <li>WhatsApp number used with our service</li>
                </ul>
              </div>


              <div className="bg-white/5 p-6 rounded-xl border border-white/10">
                <p className="text-white/70">
                  After receiving your request, we will:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2 text-white/70">
                  <li>Process your deletion request within 30 days</li>
                  <li>Send confirmation once completed</li>
                  <li>Remove all associated data from our systems</li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default DataDeletion; 