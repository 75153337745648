import React from 'react';
import { motion } from 'framer-motion';
import { FaWhatsapp, FaRobot, FaBrain, FaImage } from 'react-icons/fa';

const Feature = ({ icon: Icon, title, description, gradient }) => (
  <motion.div 
    className="relative group"
    whileHover={{ y: -5 }}
    transition={{ duration: 0.2 }}
  >
    <div className={`absolute -inset-0.5 bg-gradient-to-r ${gradient} rounded-2xl blur opacity-0 
                    group-hover:opacity-100 transition duration-500`}></div>
    <div className="relative p-8 bg-black/40 backdrop-blur-sm border border-white/5 rounded-2xl 
                  hover:border-white/10 transition duration-500">
      {/* Icon Container */}
      <div className="w-14 h-14 mb-6 relative">
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                    rounded-xl transform rotate-6 transition-transform group-hover:rotate-12" />
        <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-xl 
                    flex items-center justify-center">
          <Icon className="text-2xl text-white/80" />
        </div>
      </div>
      <h3 className="text-xl font-light mb-3 tracking-wide text-white/90">{title}</h3>
      <p className="text-white/60 font-light text-sm tracking-wide leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const ChatbotGuide = () => {
  const features = [
    {
      icon: FaRobot,
      title: "AI-Powered Intelligence",
      description: "Experience natural conversations with our advanced AI. Contextual understanding meets instant responses.",
      gradient: "from-blue-500/20 to-indigo-500/20"
    },
    {
      icon: FaBrain,
      title: "Adaptive Learning",
      description: "Our AI evolves with every interaction, delivering increasingly personalized and relevant responses.",
      gradient: "from-purple-500/20 to-pink-500/20"
    },
    {
      icon: FaImage,
      title: "Visual Creation",
      description: "Transform your ideas into stunning visuals using state-of-the-art AI image generation technology.",
      gradient: "from-emerald-500/20 to-teal-500/20"
    }
  ];

  return (
    <section className="py-32 relative overflow-hidden">
      {/* Background with refined gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
      </div>

      <div className="container mx-auto px-6 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-24"
        >
          {/* Section Badge */}
          <div className="inline-flex items-center gap-2 bg-white/5 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
            <FaWhatsapp className="text-green-400" />
            <span className="text-sm font-medium text-white/80">WhatsApp Integration</span>
          </div>

          {/* Section Title */}
          <h2 className="text-4xl md:text-5xl font-light mb-6 tracking-tight">
            Experience the Power of
            <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
              AI on WhatsApp
            </span>
          </h2>
          
          {/* Section Description */}
          <p className="text-lg text-white/60 font-light max-w-2xl mx-auto tracking-wide">
            Transform your messaging experience with our AI-powered WhatsApp bot. Seamless integration meets powerful capabilities.
          </p>
        </motion.div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <Feature {...feature} />
            </motion.div>
          ))}
        </div>

        {/* Bottom CTA */}
        <motion.div
          className="mt-24 text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <a 
            href="https://wa.me/your_whatsapp_number" 
            target="_blank" 
            rel="noopener noreferrer"
            className="group relative inline-flex items-center gap-3 bg-white/5 hover:bg-white/10 
                     backdrop-blur-sm px-8 py-4 rounded-xl border border-white/10 hover:border-white/20 
                     transition-all duration-300"
          >
            <FaWhatsapp className="text-xl text-white/80" />
            <span className="text-white/90 font-light tracking-wide">Start Chatting</span>
            <svg 
              className="w-4 h-4 text-white/70 transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default ChatbotGuide; 