import React from 'react';

const PrimaryButton = ({ children, onClick, href }) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className="inline-block px-6 py-3 bg-gradient-to-r from-primary-500 to-accent-600 text-white rounded-full font-semibold hover:from-primary-600 hover:to-accent-700 transform hover:scale-105 transition-all duration-200 shadow-lg"
    >
      {children}
    </a>
  );
};

export default PrimaryButton; 