import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Pricing from './pages/Pricing';
import AIChatBot from './pages/products/AIChatBot';
import ImageGenerator from './pages/products/ImageGenerator';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import DataDeletion from './pages/DataDeletion';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-secondary-900 flex flex-col">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/products/chat-bot" element={<AIChatBot />} />
            <Route path="/products/image-generator" element={<ImageGenerator />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/data-deletion" element={<DataDeletion />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );  
}

export default App;