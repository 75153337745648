import React from 'react';
import { motion } from 'framer-motion';
import { FaShieldAlt, FaLock, FaUserSecret, FaDatabase, FaCloudUploadAlt, FaWhatsapp, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PrivacySection = ({ icon: Icon, title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="mb-12"
  >
    <div className="flex items-start gap-4">
      <div className="w-12 h-12 bg-gradient-to-br from-primary-500/20 to-accent-500/20 rounded-lg 
                    flex items-center justify-center border border-white/10">
        <Icon className="text-white/80 text-2xl" />
      </div>
      <div>
        <h2 className="text-2xl font-bold text-white mb-4">{title}</h2>
        <div className="text-gray-400 space-y-4">
          {children}
        </div>
      </div>
    </div>
  </motion.div>
);

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-secondary-900">
      <div className="relative pt-32 pb-20 overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
              <FaShieldAlt className="text-primary-400" />
              <span className="text-sm font-medium text-white">Privacy Policy</span>
            </div>

            <h1 className="text-4xl font-bold text-white mb-8">
              Privacy Policy and
              <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
                Data Handling Practices
              </span>
            </h1>

            <div className="prose prose-invert max-w-none">
              <PrivacySection icon={FaUserSecret} title="Information We Collect">
                <p>
                  We maintain a minimalist approach to data collection. The only information we collect and store is:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Email address (required for account creation and authentication)</li>
                  <li>Basic account information necessary for service functionality</li>
                </ul>
                <p className="mt-4">
                  We do not store your conversations, generated images, or any other content you create using our service.
                </p>
              </PrivacySection>

              <PrivacySection icon={FaCloudUploadAlt} title="Third-Party Services">
                <p>
                  Our service integrates with several third-party APIs to provide functionality:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>WhatsApp Business API for message handling</li>
                  <li>OpenAI's API for AI-powered responses</li>
                  <li>Stability AI for image generation</li>
                  <li>Authentication services for account management</li>
                </ul>
                <p className="mt-4">
                  When you use our service, your interactions are processed in real-time through these third-party services. 
                  Each service has its own privacy policy and data handling practices. We encourage you to review their 
                  respective privacy policies:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li><a href="https://www.whatsapp.com/legal/privacy-policy" className="text-primary-400">WhatsApp Privacy Policy</a></li>
                  <li><a href="https://openai.com/policies/privacy-policy" className="text-primary-400">OpenAI Privacy Policy</a></li>
                  <li><a href="https://stability.ai/privacy-policy" className="text-primary-400">Stability AI Privacy Policy</a></li>
                </ul>
              </PrivacySection>

              <PrivacySection icon={FaLock} title="Data Security">
                <p>
                  While we maintain minimal data storage, we ensure the security of your account information through:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Industry-standard encryption for stored email addresses</li>
                  <li>Secure authentication protocols</li>
                  <li>Regular security updates and monitoring</li>
                </ul>
              </PrivacySection>

              <PrivacySection icon={FaDatabase} title="Data Retention">
                <p>
                  Our data retention policy is simple:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>We retain your email address and account information only for as long as you maintain an active account</li>
                  <li>You can request account deletion at any time</li>
                  <li>Upon account deletion, all associated information is permanently removed from our systems</li>
                </ul>
              </PrivacySection>

              <PrivacySection icon={FaShieldAlt} title="Your Rights">
                <p>
                  You have the following rights regarding your data:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Right to request information about your stored data</li>
                  <li>Right to request account deletion</li>
                  <li>Right to update your email address</li>
                </ul>
                <p className="mt-4">
                  To exercise these rights or for any privacy-related inquiries, please contact us at privacy@Wapertu.com
                </p>
              </PrivacySection>

              <PrivacySection icon={FaWhatsapp} title="WhatsApp Data Handling">
                <p>
                  When using our WhatsApp integration:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Messages are processed in real-time and not stored</li>
                  <li>We comply with WhatsApp Business API terms</li>
                  <li>Your WhatsApp number is used only for service delivery</li>
                  <li>You can opt-out of services at any time</li>
                </ul>
              </PrivacySection>

              <PrivacySection icon={FaTrash} title="Data Deletion">
                <p>
                  We provide a dedicated process for data deletion requests. You can:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Visit our <Link to="/data-deletion" className="text-primary-400">Data Deletion Page</Link></li>
                  <li>Email us at privacy@wapertu.com</li>
                  <li>Request deletion through WhatsApp settings</li>
                </ul>
                <p className="mt-4">
                  All deletion requests are processed within 30 days, and we'll send confirmation once completed.
                </p>
              </PrivacySection>
            </div>

            <div className="mt-12 p-6 bg-white/5 border border-white/10 rounded-xl">
              <p className="text-gray-400 text-sm">
                Last updated: {new Date().toLocaleDateString()}
              </p>
              <p className="text-gray-400 text-sm mt-2">
                For any questions about our privacy practices, please contact us at support@Wapertu.com
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 