import React from 'react';
import { motion } from 'framer-motion';
import { FaImage, FaMagic, FaPalette, FaDownload, FaWhatsapp, FaArrowLeft, FaArrowRight, FaRobot } from 'react-icons/fa';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Feature = ({ icon: Icon, title, description, gradient }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="relative group h-full"
  >
    {/* Animated Gradient Background */}
    <div className={`absolute -inset-[1px] bg-gradient-to-r ${gradient} rounded-3xl blur-lg opacity-0 
                    group-hover:opacity-100 transition-all duration-700 animate-pulse`}></div>
    
    {/* Glass Container */}
    <div className="relative h-full bg-black/40 backdrop-blur-md border border-white/5 rounded-3xl p-10
                  hover:border-white/10 transition-all duration-700 flex flex-col justify-between">
      {/* Icon Container */}
      <div className="w-16 h-16 mb-8 relative">
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                    rounded-2xl transform rotate-6 transition-transform group-hover:rotate-12" />
        <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-2xl 
                    flex items-center justify-center">
          <Icon className="text-2xl text-white/80" />
        </div>
      </div>
      
      <div>
        <h3 className="text-2xl font-light mb-4 tracking-wide text-white/90">{title}</h3>
        <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);

const ImageCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ],
    customPaging: (i) => (
      <div className="w-2 h-2 mx-1 rounded-full bg-white/30 hover:bg-white/50 transition-colors" />
    )
  };

  return (
    <div className="relative px-4 md:px-12 max-w-6xl mx-auto">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="px-2">
            <div className="relative aspect-[4/5] sm:aspect-[3/4] md:aspect-[16/9] lg:aspect-[21/9] rounded-3xl overflow-hidden">
              <div className="absolute -inset-4 bg-gradient-to-r from-primary-500/20 to-accent-500/20 rounded-3xl blur-xl"></div>
              <div className="relative h-full">
                <img
                  src={image.url}
                  alt={image.prompt}
                  className="w-full h-full object-cover rounded-3xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent">
                  <div className="absolute bottom-0 left-0 right-0 p-4 md:p-8">
                    <p className="text-white/90 text-sm md:text-lg font-light tracking-wide line-clamp-2 md:line-clamp-3">
                      {image.prompt}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-4 top-1/2 -translate-y-1/2 z-10 p-3 bg-black/50 hover:bg-black/70 
             rounded-full text-white/70 hover:text-white transition-all duration-200"
  >
    <FaArrowRight className="w-5 h-5" />
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-4 top-1/2 -translate-y-1/2 z-10 p-3 bg-black/50 hover:bg-black/70 
             rounded-full text-white/70 hover:text-white transition-all duration-200"
  >
    <FaArrowLeft className="w-5 h-5" />
  </button>
);

const ImageGenerator = () => {
  const features = [
    {
      icon: FaMagic,
      title: "Text-to-Image",
      description: "Transform your ideas into stunning visuals with simple text descriptions.",
      gradient: "from-violet-500/20 via-fuchsia-500/20 to-pink-500/20"
    },
    {
      icon: FaPalette,
      title: "Style Control",
      description: "Choose from various artistic styles and customize the output.",
      gradient: "from-cyan-500/20 via-blue-500/20 to-indigo-500/20"
    },
    {
      icon: FaDownload,
      title: "Instant Delivery",
      description: "Receive your generated images instantly through WhatsApp.",
      gradient: "from-emerald-500/20 via-teal-500/20 to-cyan-500/20"
    },
    {
      icon: FaImage,
      title: "High Quality",
      description: "Get high-resolution images suitable for professional use.",
      gradient: "from-amber-500/20 via-orange-500/20 to-yellow-500/20"
    }
  ];

  const exampleImages = [
    {
      url: "/eye.jpg",
      prompt: "A close-up of a woman's eye in sharp focus, light reflecting on her face, rich brown eye colour, intricate details, soft highlights, smooth skin texture"
    },
    {
      url: "/cave1.jpg",
      prompt: "A hidden cave with a lush forest inside, sunlight filtering through dust particles, vibrant greenery, mystical atmosphere, serene beauty"
    },
    {
      url: "/bird2.png",
      prompt: "An exotic bird perched in the middle of Times Square, cinematic movie style shot, vibrant neon lights, bustling crowd, dramatic shadows, high contrast"
    },
    {
      url: "/painting.png",
      prompt: "Digital painting with vibrant colors and abstract elements"
    },
    {
      url: "/guysleeping.png",
      prompt: "Portrait of a sleeping person with dramatic lighting and soft shadows"
    }
  ];

  return (
    <div className="min-h-screen bg-secondary-900">
      {/* Hero Section */}
<section className="min-h-screen relative overflow-hidden flex items-center">
  {/* Animated Background */}
  <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
    <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
    {/* Animated Gradient Orbs */}
    <div className="absolute top-1/4 left-1/4 w-96 h-96 bg-primary-500/10 rounded-full blur-3xl animate-pulse"></div>
    <div className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-accent-500/10 rounded-full blur-3xl animate-pulse delay-1000"></div>
  </div>

  <div className="container mx-auto px-6 relative py-20">
    <div className="grid lg:grid-cols-2 gap-16 items-center">
      <div className="order-1 lg:order-1">
        {/* Premium Badge */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center gap-3 bg-white/5 backdrop-blur-md px-6 py-3 rounded-full mb-8
                   border border-white/10 hover:border-white/20 transition-all duration-500"
        >
          <div className="w-2 h-2 rounded-full bg-primary-400 animate-pulse"></div>
          <span className="text-sm font-light tracking-wider text-white/80 uppercase">AI-Powered Creation</span>
        </motion.div>

        {/* Modern Title */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-6xl md:text-7xl font-extralight mb-8 tracking-tight"
        >
          Transform
          <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                       animate-gradient bg-300% font-light">
            Text to Art
          </span>
        </motion.h1>

        {/* Feature Tags */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="flex flex-wrap gap-4 mb-8"
        >
          {['4K Resolution', 'Instant Delivery', 'Custom Styles'].map((tag, index) => (
            <div key={index} className="px-4 py-2 rounded-full bg-white/5 backdrop-blur-sm border border-white/10
                                    text-white/60 text-sm font-light">
              {tag}
            </div>
          ))}
        </motion.div>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="text-xl text-white/60 font-light mb-12 tracking-wide leading-relaxed"
        >
          Experience the future of image creation. Describe your vision, and watch as our 
          advanced AI transforms your words into stunning visual masterpieces.
        </motion.p>

        {/* CTA Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="flex flex-col sm:flex-row gap-6"
        >
          <a
            href="#try-now"
            className="group relative inline-flex items-center gap-3 bg-gradient-to-r from-primary-400/80 to-accent-400/80 
                     backdrop-blur-sm px-8 py-4 rounded-xl hover:from-primary-400 hover:to-accent-400
                     transition-all duration-300"
          >
            <FaWhatsapp className="text-xl text-white" />
            <span className="text-white font-light tracking-wide">Start Creating</span>
            <svg 
              className="w-4 h-4 text-white transform group-hover:translate-x-1 transition-transform" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </a>

          <a
            href="#examples"
            className="group relative inline-flex items-center gap-3 bg-white/5 hover:bg-white/10 
                     backdrop-blur-sm px-8 py-4 rounded-xl border border-white/10 hover:border-white/20 
                     transition-all duration-300"
          >
            <span className="text-white/90 font-light tracking-wide">View Examples</span>
            <FaImage className="text-xl text-white/80" />
          </a>
        </motion.div>
      </div>

      {/* Preview Image Section */}
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.5 }}
        className="order-2 lg:order-2"
      >
        <div className="relative">
          <div className="absolute -inset-4 bg-gradient-to-r from-primary-500/20 to-accent-500/20 rounded-3xl blur-xl"></div>
          <div className="relative aspect-square rounded-3xl overflow-hidden border border-white/10">
            <div className="absolute inset-0 bg-black/40 backdrop-blur-sm"></div>
            <div className="relative h-full p-8 flex flex-col">
              {/* User Message */}
              <div className="flex justify-end mb-6">
                <div className="max-w-[80%] bg-primary-500/20 backdrop-blur-sm rounded-2xl rounded-tr-sm p-4 border border-white/10">
                  <p className="text-white/90 font-light text-sm leading-relaxed">
                    A close-up of a woman's eye in sharp focus, light reflecting on her face, rich brown eye colour, intricate details, soft highlights, smooth skin texture
                  </p>
                </div>
              </div>

              {/* AI Response */}
              <div className="flex justify-start">
                <div className="max-w-[80%]">
                  <div className="flex items-center gap-2 mb-2">
                    <div className="w-6 h-6 rounded-full bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaRobot className="text-xs text-white/80" />
                    </div>
                    <span className="text-white/60 text-xs font-light">AI Assistant</span>
                  </div>
                  <div className="rounded-2xl rounded-tl-sm overflow-hidden border border-white/10">
                    <img 
                      src="/eye.jpg" 
                      alt="Generated eye image" 
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  </div>
</section>

      {/* Features Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Feature {...feature} gradient={feature.gradient} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Examples Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
        </div>
        
        <div className="container mx-auto relative">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-light text-center mb-16 tracking-tight"
          >
            Example
            <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                         animate-gradient bg-300% font-light">
              Generations
            </span>
          </motion.h2>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-5xl mx-auto"
          >
            <ImageCarousel images={exampleImages} />
          </motion.div>
        </div>
      </section>

      {/* Advanced Features Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="container mx-auto px-6 relative">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-1"
            >
              <h2 className="text-4xl font-light mb-8 tracking-tight">
                Powered by Advanced
                <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                             animate-gradient bg-300% font-light">
                  AI Technology
                </span>
              </h2>
              <div className="space-y-8">
                <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed">
                  Our image generation technology leverages state-of-the-art AI models to create stunning,
                  high-quality images from your text descriptions.
                </p>
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaMagic className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Advanced Generation</h4>
                      <p className="text-white/60 font-light">Creates detailed, high-quality images</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaPalette className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Style Control</h4>
                      <p className="text-white/60 font-light">Customize artistic styles and aesthetics</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaDownload className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Export Options</h4>
                      <p className="text-white/60 font-light">Download images in PNG and JPG formats</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Added Visual Element */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-2 hidden lg:block"
            >
              <div className="relative">
                <div className="absolute -inset-4 bg-gradient-to-r from-primary-500/20 to-accent-500/20 rounded-3xl blur-xl"></div>
                <div className="relative grid grid-cols-2 gap-4 p-4">
                  {[
                    { url: '/messyroom.png', alt: 'Messy Room' },
                    { url: '/cakewo.png', alt: 'Woman with a cake dress' },
                    { url: '/fandf.png', alt: 'Fast car b&w' },
                    { url: '/logocre.png', alt: 'Logo Creation' }
                  ].map((image, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      whileInView={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="relative aspect-square rounded-2xl overflow-hidden group"
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 to-accent-500/10 
                                   group-hover:opacity-75 transition-opacity duration-300"></div>
                      <img
                        src={image.url}
                        alt={image.alt}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 
                                   group-hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
                        <div className="w-full h-1 bg-gradient-to-r from-primary-400 to-accent-400 rounded-full 
                                     transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ImageGenerator; 