import React from 'react';
import { motion } from 'framer-motion';

const ImageSection = ({ title, description, imageUrl, reverse }) => {
  return (
    <section className={`py-32 relative overflow-hidden ${reverse ? 'bg-black/40' : 'bg-black/20'}`}>
      {/* Background Elements */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
      </div>

      {/* Floating particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(8)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-1 h-1 bg-primary-500/20 rounded-full"
            animate={{
              y: ['0vh', '100vh'],
              x: Math.sin(i) * 10
            }}
            transition={{
              duration: 8 + Math.random() * 6,
              repeat: Infinity,
              delay: i * 0.2
            }}
            style={{
              left: `${(i / 8) * 100}%`,
              top: `-${Math.random() * 100}%`
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-6 relative">
        <div className={`flex flex-col ${reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'} items-center gap-16`}>
          <motion.div
            className="flex-1"
            initial={{ opacity: 0, x: reverse ? 50 : -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="relative group">
              <div className="absolute -inset-4 bg-gradient-to-r from-primary-500 to-accent-500 rounded-lg opacity-30 blur-xl 
                            group-hover:opacity-40 transition-opacity duration-300"></div>
              <div className="relative">
                <img 
                  src={imageUrl} 
                  alt={title} 
                  className="w-full rounded-lg shadow-2xl border border-white/10"
                />
                {/* Overlay gradient */}
                <div className="absolute inset-0 bg-gradient-to-tr from-primary-500/20 to-transparent rounded-lg"></div>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="flex-1 text-white"
            initial={{ opacity: 0, x: reverse ? -50 : 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
              <div className="w-2 h-2 rounded-full bg-primary-400"></div>
              <span className="text-sm font-medium text-primary-200">AI Features</span>
            </div>
            
            <h2 className="text-4xl md:text-5xl font-bold mb-6">
              {title.split(' ').map((word, i) => (
                <span key={i} className={i === 1 ? "block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text" : ""}>
                  {word + ' '}
                </span>
              ))}
            </h2>
            
            <p className="text-lg text-gray-300 mb-8 leading-relaxed">
              {description}
            </p>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center gap-2 bg-white/10 hover:bg-white/20 backdrop-blur-sm px-6 py-3 rounded-xl
                         border border-white/10 hover:border-white/20 transition-all duration-300"
            >
              Learn More
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </motion.button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ImageSection; 