import React from 'react';
import { motion } from 'framer-motion';
import { FaRobot, FaComments, FaBrain, FaLock, FaWhatsapp } from 'react-icons/fa';
import ChatDemo from '../../components/ChatDemo';

const Feature = ({ icon: Icon, title, description, gradient }) => (
  <motion.div
    whileHover={{ y: -5 }}
    className="relative group h-full"
  >
    {/* Animated Gradient Background */}
    <div className={`absolute -inset-[1px] bg-gradient-to-r ${gradient} rounded-3xl blur-lg opacity-0 
                    group-hover:opacity-100 transition-all duration-700 animate-pulse`}></div>
    
    {/* Glass Container */}
    <div className="relative h-full bg-black/40 backdrop-blur-md border border-white/5 rounded-3xl p-10
                  hover:border-white/10 transition-all duration-700 flex flex-col justify-between">
      {/* Icon Container */}
      <div className="w-16 h-16 mb-8 relative">
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/10 to-accent-500/10 
                    rounded-2xl transform rotate-6 transition-transform group-hover:rotate-12" />
        <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-2xl 
                    flex items-center justify-center">
          <Icon className="text-2xl text-white/80" />
        </div>
      </div>
      
      <h3 className="text-2xl font-light mb-4 tracking-wide text-white/90">{title}</h3>
      <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const AIChatBot = () => {
  const features = [
    {
      icon: FaBrain,
      title: "Advanced AI Understanding",
      description: "Powered by GPT-4, our bot understands context and provides intelligent responses.",
      gradient: "from-violet-500/20 via-fuchsia-500/20 to-pink-500/20"
    },
    {
      icon: FaComments,
      title: "Natural Conversations",
      description: "Engage in fluid, human-like conversations with contextual awareness.",
      gradient: "from-cyan-500/20 via-blue-500/20 to-indigo-500/20"
    },
    {
      icon: FaRobot,
      title: "24/7 Availability",
      description: "Get instant responses any time of day, without waiting.",
      gradient: "from-emerald-500/20 via-teal-500/20 to-cyan-500/20"
    },
    {
      icon: FaLock,
      title: "Secure Messaging",
      description: "End-to-end encryption ensures your conversations remain private.",
      gradient: "from-amber-500/20 via-orange-500/20 to-yellow-500/20"
    }
  ];

  return (
    <div className="min-h-screen bg-secondary-900">
      {/* Hero Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
        </div>

        <div className="container mx-auto px-6 relative">
          <div className="max-w-4xl mx-auto text-center">
            {/* Premium Badge */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center gap-3 bg-white/5 backdrop-blur-md px-6 py-3 rounded-full mb-8
                       border border-white/10 hover:border-white/20 transition-all duration-500"
            >
              <FaWhatsapp className="text-green-400" />
              <span className="text-sm font-light tracking-wider text-white/80 uppercase">WhatsApp AI Chat Bot</span>
            </motion.div>

            {/* Elegant Title */}
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-5xl md:text-6xl font-extralight mb-8 tracking-tight"
            >
              Your Personal
              <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                           animate-gradient bg-300% font-light">
                AI Assistant
              </span>
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-lg text-white/60 font-light mb-12 tracking-wide leading-relaxed max-w-2xl mx-auto"
            >
              Experience intelligent conversations powered by advanced AI. Get instant responses,
              answers, and assistance - all through WhatsApp.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="flex flex-col sm:flex-row gap-6 justify-center"
            >
              <a
                href="#try-now"
                className="group relative inline-flex items-center gap-3 bg-white/5 hover:bg-white/10 
                         backdrop-blur-sm px-8 py-4 rounded-xl border border-white/10 hover:border-white/20 
                         transition-all duration-300"
              >
                <FaWhatsapp className="text-xl text-white/80" />
                <span className="text-white/90 font-light tracking-wide">Try it Now</span>
                <svg 
                  className="w-4 h-4 text-white/70 transform group-hover:translate-x-1 transition-transform" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                </svg>
              </a>
              
              <a
                href="#learn-more"
                className="group relative inline-flex items-center gap-3 bg-white/5 hover:bg-white/10 
                         backdrop-blur-sm px-8 py-4 rounded-xl border border-white/10 hover:border-white/20 
                         transition-all duration-300"
              >
                <span className="text-white/90 font-light tracking-wide">Watch Demo</span>
                <svg 
                  className="w-4 h-4 text-white/70 transform group-hover:translate-x-1 transition-transform" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </a>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 xl:gap-12">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Feature {...feature} gradient={feature.gradient} />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Demo Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
        </div>
        
        <div className="container mx-auto px-6 relative">
          <div className="flex flex-col lg:flex-row items-center gap-16">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="flex-1 max-w-xl"
            >
              <h2 className="text-4xl font-light mb-8 tracking-tight">
                Experience Natural
                <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                             animate-gradient bg-300% font-light">
                  Conversations
                </span>
              </h2>
              <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed mb-12">
                Our AI chat bot understands context, remembers previous conversations, and provides
                meaningful responses that feel natural and helpful.
              </p>
              <div className="flex items-center gap-8">
                <div className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-green-400"></div>
                  <span className="text-white/60 font-light">Instant Responses</span>
                </div>
                <div className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-blue-400"></div>
                  <span className="text-white/60 font-light">24/7 Available</span>
                </div>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="flex-1"
            >
              <ChatDemo />
            </motion.div>
          </div>
        </div>
      </section>

      {/* Advanced Features Section */}
      <section className="py-32 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
          <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
        </div>
        
        <div className="container mx-auto px-6 relative">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-2 lg:order-1"
            >
              <div className="relative aspect-square rounded-3xl overflow-hidden">
                <div className="absolute -inset-4 bg-gradient-to-r from-primary-500/20 to-accent-500/20 rounded-3xl blur-xl"></div>
                <div className="relative h-full bg-black/40 backdrop-blur-md border border-white/10 rounded-3xl p-8
                             flex flex-col justify-center items-center text-center">
                  <div className="space-y-6">
                    <div className="flex justify-center">
                      <div className="w-20 h-20 relative">
                        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                    rounded-2xl transform rotate-6 transition-transform group-hover:rotate-12" />
                        <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-2xl 
                                    flex items-center justify-center">
                          <FaBrain className="text-4xl text-white/80" />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <div className="h-2 w-3/4 mx-auto bg-white/10 rounded animate-pulse"></div>
                      <div className="h-2 w-1/2 mx-auto bg-white/10 rounded animate-pulse"></div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="order-1 lg:order-2"
            >
              <h2 className="text-4xl font-light mb-8 tracking-tight">
                Powered by Advanced
                <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                             animate-gradient bg-300% font-light">
                  Language Models
                </span>
              </h2>
              <div className="space-y-8">
                <p className="text-white/60 font-light text-lg tracking-wide leading-relaxed">
                  Our AI chatbot leverages state-of-the-art language models to deliver human-like conversations 
                  with unprecedented understanding and context awareness.
                </p>
                <div className="space-y-6">
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaBrain className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Deep Understanding</h4>
                      <p className="text-white/60 font-light">Comprehends context and nuances in conversations</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaComments className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Memory Retention</h4>
                      <p className="text-white/60 font-light">Maintains context throughout conversations</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="w-12 h-12 rounded-2xl bg-gradient-to-r from-primary-500/20 to-accent-500/20 
                                 flex items-center justify-center">
                      <FaRobot className="text-xl text-white/80" />
                    </div>
                    <div>
                      <h4 className="text-white/90 font-light text-lg mb-1">Adaptive Learning</h4>
                      <p className="text-white/60 font-light">Improves responses based on interactions</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AIChatBot;