import React from 'react';
import { motion } from 'framer-motion';
import { FaQuoteLeft, FaStar } from 'react-icons/fa';

const TestimonialCard = ({ content, author, role, rating, delay, gradient, accent }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.8, delay }}
    className="relative group h-full"
  >
    {/* Animated Gradient Background */}
    <div className={`absolute -inset-[1px] bg-gradient-to-r ${gradient} rounded-3xl blur-lg opacity-0 
                    group-hover:opacity-100 transition-all duration-700 animate-pulse`}></div>
    
    {/* Glass Container */}
    <div className="relative h-full bg-black/40 backdrop-blur-md border border-white/5 rounded-3xl p-10
                  hover:border-white/10 transition-all duration-700 flex flex-col">
      {/* Accent Line */}
      <div className={`absolute top-0 left-1/2 -translate-x-1/2 w-24 h-1 ${accent} rounded-full blur-sm`}></div>

      {/* Quote Icon */}
      <div className="mb-8">
        <div className="w-16 h-16 relative">
          <div className={`absolute inset-0 ${gradient} rounded-2xl blur-md opacity-50`}></div>
          <div className="absolute inset-0 bg-black/40 backdrop-blur-sm rounded-2xl 
                       flex items-center justify-center border border-white/10">
            <FaQuoteLeft className="text-white/60 text-2xl" />
          </div>
        </div>
      </div>

      {/* Rating */}
      <div className="flex gap-1 mb-6">
        {[...Array(5)].map((_, i) => (
          <FaStar key={i} className={`text-sm ${i < rating ? accent.replace('bg-', 'text-') : 'text-white/20'}`} />
        ))}
      </div>

      {/* Content */}
      <p className="text-white/80 font-light leading-relaxed tracking-wide text-lg mb-8 flex-grow">
        {content}
      </p>

      {/* Author Info */}
      <div className="flex items-center gap-5 pt-6 border-t border-white/5">
        <div className={`w-14 h-14 rounded-2xl ${gradient} p-[1px]`}>
          <div className="w-full h-full rounded-2xl bg-black/40 backdrop-blur-sm 
                       flex items-center justify-center text-xl font-light text-white/90">
            {author[0]}
          </div>
        </div>
        <div>
          <h4 className="text-white/90 font-light tracking-wide text-lg">{author}</h4>
          <p className="text-white/50 text-sm tracking-wider uppercase">{role}</p>
        </div>
      </div>
    </div>
  </motion.div>
);

const Testimonials = () => {
  const testimonials = [
    {
      content: "The image generation capabilities are beyond extraordinary. Each creation surpasses my expectations with unmatched detail and artistic brilliance.",
      author: "Sarah Johnson",
      role: "Digital Artist",
      rating: 5,
      gradient: "from-violet-500/20 via-fuchsia-500/20 to-pink-500/20",
      accent: "bg-fuchsia-500"
    },
    {
      content: "The AI's cognitive abilities are remarkable. It understands context perfectly and provides responses that feel incredibly human and insightful.",
      author: "Michael Chen",
      role: "Tech Innovator",
      rating: 5,
      gradient: "from-cyan-500/20 via-blue-500/20 to-indigo-500/20",
      accent: "bg-blue-500"
    },
    {
      content: "Enterprise-grade security combined with cutting-edge AI technology. It's the perfect balance of innovation and trustworthiness.",
      author: "Emma Davis",
      role: "Security Expert",
      rating: 5,
      gradient: "from-emerald-500/20 via-teal-500/20 to-cyan-500/20",
      accent: "bg-teal-500"
    }
  ];

  return (
    <section className="py-40 relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900/20">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-5"></div>
      </div>

      <div className="container mx-auto px-6 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-32"
        >
          {/* Premium Badge */}
          <div className="inline-flex items-center gap-3 bg-white/5 backdrop-blur-md px-6 py-3 rounded-full mb-8
                       border border-white/10 hover:border-white/20 transition-all duration-500">
            <div className="w-2 h-2 rounded-full bg-primary-400 animate-pulse"></div>
            <span className="text-sm font-light tracking-wider text-white/80 uppercase">Client Testimonials</span>
          </div>

          {/* Elegant Title */}
          <h2 className="text-5xl md:text-6xl font-extralight mb-8 tracking-tight">
            Trusted by
            <span className="block mt-3 bg-gradient-to-r from-primary-400 via-accent-400 to-primary-400 bg-clip-text text-transparent
                         animate-gradient bg-300% font-light">
              Industry Leaders
            </span>
          </h2>
        </motion.div>

        {/* Testimonials Grid */}
        <div className="grid md:grid-cols-3 gap-8 xl:gap-12">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard 
              key={index} 
              {...testimonial} 
              delay={index * 0.2} 
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

// Add to your global CSS:
/*
.animate-gradient {
  animation: gradient 8s linear infinite;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  100% { background-position: 300% 50%; }
}

.bg-300% {
  background-size: 300% 100%;
}
*/ 