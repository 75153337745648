import React from 'react';
import { motion } from 'framer-motion';
import { FaRobot, FaWhatsapp } from 'react-icons/fa';

const Hero = () => {
  return (
    <div className="relative min-h-screen flex items-center">
      {/* Dynamic background */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-primary-900">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-20"></div>
      </div>

      {/* Floating elements */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2 bg-primary-500/30 rounded-full"
            animate={{
              y: ['0vh', '100vh'],
              x: Math.sin(i) * 20
            }}
            transition={{
              duration: 10 + Math.random() * 10,
              repeat: Infinity,
              delay: i * 0.2
            }}
            style={{
              left: `${(i / 20) * 100}%`,
              top: `-${Math.random() * 100}%`
            }}
          />
        ))}
      </div>

      {/* Main content */}
      <div className="container mx-auto px-6 relative z-10">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Left side - Text content */}
          <motion.div 
            className="flex-1 text-white"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
              <FaRobot className="text-primary-400" />
              <span className="text-sm font-medium">AI-Powered WhatsApp Bot</span>
            </div>
            
            <h1 className="text-5xl md:text-7xl font-bold mb-6">
              Your Personal
              <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
                AI Assistant
              </span>
            </h1>
            
            <p className="text-lg text-gray-300 mb-8 max-w-xl">
              Transform your WhatsApp into a powerful AI companion. Generate images, get answers, and automate tasks - all through simple chat messages.
            </p>

            <div className="flex flex-col sm:flex-row gap-4">
              <a href="#try-now" className="group relative inline-flex items-center gap-2 bg-primary-500 hover:bg-primary-600 text-white px-8 py-4 rounded-xl font-medium transition-all duration-300 overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-primary-400 to-accent-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <FaWhatsapp className="text-xl relative z-10" />
                <span className="relative z-10">Try it Now</span>
              </a>
              
              <a href="#learn-more" className="inline-flex items-center gap-2 border border-white/20 hover:border-white/40 text-white px-8 py-4 rounded-xl font-medium backdrop-blur-sm transition-all duration-300">
                Watch Demo
              </a>
            </div>
          </motion.div>

          {/* Right side - Visual element */}
          <motion.div 
            className="flex-1"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-primary-500 to-accent-500 rounded-lg opacity-5 blur-xl"></div>
              <img 
                src="/phone_1.svg" 
                alt="WhatsApp AI Bot Interface" 
                className="w-full max-w-[460px] h-auto mx-auto relative z-10 rounded-lg"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;