import React from 'react';
import { motion } from 'framer-motion';
import { FaShieldAlt, FaFileContract, FaMoneyBillWave, FaCreditCard, FaGavel, FaInfoCircle } from 'react-icons/fa';

const TermsSection = ({ icon: Icon, title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="mb-12"
  >
    <div className="flex items-start gap-4">
      <div className="w-12 h-12 bg-gradient-to-br from-primary-500/20 to-accent-500/20 rounded-lg 
                    flex items-center justify-center border border-white/10">
        <Icon className="text-white/80 text-2xl" />
      </div>
      <div>
        <h2 className="text-2xl font-bold text-white mb-4">{title}</h2>
        <div className="text-gray-400 space-y-4">
          {children}
        </div>
      </div>
    </div>
  </motion.div>
);

const TermsAndConditions = () => {
  return (
    <div className="min-h-screen bg-secondary-900">
      <div className="relative pt-32 pb-20 overflow-hidden">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-10"></div>
        
        <div className="container mx-auto px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <div className="inline-flex items-center gap-2 bg-white/10 backdrop-blur-sm px-4 py-2 rounded-full mb-6">
              <FaFileContract className="text-primary-400" />
              <span className="text-sm font-medium text-white">Terms & Conditions</span>
            </div>

            <h1 className="text-4xl font-bold text-white mb-8">
              Terms of Service and
              <span className="block mt-2 bg-gradient-to-r from-primary-400 to-accent-400 text-transparent bg-clip-text">
                Subscription Policies
              </span>
            </h1>

            <div className="prose prose-invert max-w-none">
              <TermsSection icon={FaFileContract} title="Subscription Terms">
                <p>
                  By subscribing to Wapertu's services, you agree to the following terms:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Subscriptions are billed on a monthly basis</li>
                  <li>Access to services begins immediately upon successful payment</li>
                  <li>Subscription automatically renews unless cancelled</li>
                </ul>
              </TermsSection>

              <TermsSection icon={FaMoneyBillWave} title="Cancellation Policy">
                <p>
                  Our cancellation policy is designed to be transparent and fair:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>You may not cancel your subscription at any time through your account settings</li>
                  <li>Cancellation will take effect at the end of your current billing period</li>
                  <li>No partial refunds are provided for unused subscription time</li>
                  <li>Free trial cancellations must be made before the trial period ends to avoid charges</li>
                </ul>
              </TermsSection>

              <TermsSection icon={FaCreditCard} title="Billing & Refunds">
                <p>
                  Our billing and refund policies:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>All payments are processed securely through Stripe</li>
                  <li>Subscriptions are auto-renewed unless cancelled</li>
                  <li>We do not offer refunds for digital services already rendered</li>
                  <li>Billing disputes must be raised within 30 days of the charge</li>
                </ul>
                <p className="mt-4">
                  For billing inquiries, please contact our support team at support@wapertu.com
                </p>
              </TermsSection>

              <TermsSection icon={FaShieldAlt} title="Service Usage">
                <p>
                  Guidelines for using our services:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Services are for personal or business use only</li>
                  <li>Reselling or redistributing our services is allowed</li>
                  <li>Users must comply with WhatsApp's terms of service</li>
                  <li>Abuse or excessive usage may result in service limitation</li>
                </ul>
              </TermsSection>

              <TermsSection icon={FaGavel} title="Dispute Resolution">
                <p>
                  In case of disputes:
                </p>
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Contact our support team first at support@wapertu.com</li>
                  <li>We aim to resolve issues within 48 business hours</li>
                  <li>Mediation may be used before legal proceedings</li>
                </ul>
              </TermsSection>

              <TermsSection icon={FaInfoCircle} title="Business Information">
                
                <ul className="list-disc pl-6 mt-4 space-y-2">
                  <li>Operating as a digital-only service provider</li>
                  <li>All services are provided remotely</li>
                  <li>Primary contact: support@wapertu.com</li>
                  <li>Operating in compliance with digital service provider regulations</li>
                </ul>
              </TermsSection>
            </div>

            <div className="mt-12 p-6 bg-white/5 border border-white/10 rounded-xl">
              <p className="text-gray-400 text-sm">
                Last updated: {new Date().toLocaleDateString()}
              </p>
              <p className="text-gray-400 text-sm mt-2">
                For any questions about our terms and conditions, please contact us at support@wapertu.com
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions; 