import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheck, FaCrown, FaRobot, FaImage } from 'react-icons/fa';

const PricingCard = ({ plan, price, features, recommended, delay, gradientClasses, buttonGradient }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay }}
    className="relative group h-full"
  >
    <div className="relative rounded-2xl overflow-hidden h-full">
      <div className={`absolute inset-0 bg-gradient-to-r ${gradientClasses} blur-sm`}></div>
      
      <div className="relative bg-black/40 backdrop-blur-sm p-6 sm:p-8 h-full">
        {recommended && (
          <div className={`absolute top-3 right-3 bg-gradient-to-r ${buttonGradient} 
                       px-3 py-1 rounded-full text-[10px] tracking-wider uppercase font-light`}>
            Premium
          </div>
        )}

        <div className="mb-8">
          <h3 className="text-xl sm:text-2xl font-light text-white mb-2">{plan}</h3>
          <div className="flex items-baseline gap-1.5">
            <span className="text-4xl sm:text-5xl font-extralight text-white">${price}</span>
            <span className="text-white/40 font-light text-sm">/month</span>
          </div>
        </div>
        
        <ul className="space-y-4 mb-8">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-3">
              <div className={`w-1 h-1 rounded-full ${
                buttonGradient.includes('purple') ? 'bg-purple-400' : 'bg-primary-400'
              }`}></div>
              <span className="text-sm text-white/70 font-light">{feature}</span>
            </li>
          ))}
        </ul>
        
        <div className="mt-auto">
          <button className={`w-full py-3.5 rounded-xl text-sm font-light tracking-wide transition-all duration-300
            ${recommended 
              ? `bg-gradient-to-r ${buttonGradient} text-white hover:opacity-90` 
              : 'bg-white/5 text-white/90 hover:bg-white/10'
            }`}>
            Get Started
          </button>
        </div>
      </div>
    </div>
  </motion.div>
);

const Pricing = () => {
  const [activeService, setActiveService] = useState('chatbot');

  const chatbotPlans = [
    {
      plan: "Basic",
      price: "0",
      features: [
        "100 AI messages per day",
        "Basic chat features",
        "Standard response time",
        "Community support"
      ]
    },
    {
      plan: "Pro",
      price: "29",
      features: [
        "Unlimited AI messages",
        "Advanced chat features",
        "Priority response time",
        "24/7 Premium support",
        "Custom AI training",
        "Analytics dashboard"
      ],
      recommended: true
    },
    {
      plan: "Enterprise",
      price: "99",
      features: [
        "Everything in Pro",
        "Custom API access",
        "Dedicated account manager",
        "Custom integration support",
        "SLA guarantees",
        "White-label solution"
      ]
    }
  ];

  const imagePlans = [
    {
      plan: "Basic",
      price: "0",
      features: [
        "10 images per day",
        "Basic resolution",
        "Standard styles",
        "Community support"
      ]
    },
    {
      plan: "Pro",
      price: "49",
      features: [
        "100 images per day",
        "4K resolution",
        "All style options",
        "Priority support",
        "Commercial usage",
        "Advanced editing"
      ],
      recommended: true
    },
    {
      plan: "Enterprise",
      price: "149",
      features: [
        "Unlimited images",
        "Custom API access",
        "Dedicated manager",
        "Custom integration",
        "SLA guarantees",
        "White-label option"
      ]
    }
  ];

  // Get gradient classes based on active service
  const getGradientClasses = (isChatbot) => {
    return isChatbot 
      ? 'from-purple-500/20 to-violet-500/20' // Purple gradient for chatbot
      : 'from-primary-500/20 to-accent-500/20'; // Original gradient for image generator
  };

  const getButtonGradient = (isChatbot) => {
    return isChatbot 
      ? 'from-purple-500 to-violet-500' // Purple gradient for chatbot
      : 'from-primary-500 to-accent-500'; // Original gradient for image generator
  };

  return (
    <div className="relative min-h-[100dvh] bg-secondary-900 w-full overflow-x-hidden pt-20">
      <div className="fixed inset-0 bg-gradient-to-br from-black via-secondary-900 to-primary-900/10">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-[0.02]"></div>
        <div className={`absolute top-0 -left-40 w-80 h-80 rounded-full blur-[100px] animate-pulse
          ${activeService === 'chatbot' ? 'bg-purple-500/10' : 'bg-primary-500/10'}`}
        ></div>
        <div className={`absolute bottom-0 -right-40 w-80 h-80 rounded-full blur-[100px] animate-pulse delay-1000
          ${activeService === 'chatbot' ? 'bg-violet-500/10' : 'bg-accent-500/10'}`}
        ></div>
      </div>

      <div className="relative w-full min-h-[calc(100vh-5rem)] flex flex-col">
        <div className="flex-grow flex flex-col items-center justify-center p-4 sm:p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-xs mb-12 relative z-10"
          >
            <div className="bg-black/20 backdrop-blur-sm rounded-full p-1 border border-white/10">
              <div className="relative flex">
                {['chatbot', 'images'].map((service) => (
                  <button
                    key={service}
                    onClick={() => setActiveService(service)}
                    className={`relative z-10 flex-1 px-4 py-2.5 rounded-full text-xs tracking-wide transition-all duration-300
                              ${activeService === service ? 'text-white' : 'text-white/40'}`}
                  >
                    <div className="flex items-center justify-center gap-2">
                      {service === 'chatbot' ? <FaRobot /> : <FaImage />}
                      <span>{service === 'chatbot' ? 'AI Chatbot' : 'Image Generator'}</span>
                    </div>
                  </button>
                ))}
                <div
                  className={`absolute inset-y-0 w-1/2 bg-gradient-to-r ${getGradientClasses(activeService === 'chatbot')} rounded-full
                           transition-transform duration-300 ${activeService === 'images' ? 'translate-x-full' : 'translate-x-0'}`}
                ></div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-3xl sm:text-4xl font-extralight mb-3 tracking-tight">
              Choose Your
              <span className={`block mt-1 bg-gradient-to-r ${
                activeService === 'chatbot' 
                  ? 'from-purple-400 via-violet-400 to-purple-400' 
                  : 'from-primary-400 via-accent-400 to-primary-400'
              } bg-clip-text text-transparent`}>
                {activeService === 'chatbot' ? 'AI Assistant' : 'Creative Partner'}
              </span>
            </h1>
            
            <p className="text-sm text-white/40 font-light max-w-xs mx-auto">
              {activeService === 'chatbot' 
                ? 'Experience seamless AI interactions on WhatsApp.'
                : 'Unlock limitless creative possibilities with AI.'}
            </p>
          </motion.div>

          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 max-w-[1200px] mx-auto px-4">
            {(activeService === 'chatbot' ? chatbotPlans : imagePlans).map((plan, index) => (
              <PricingCard 
                key={index} 
                {...plan} 
                delay={index * 0.1}
                gradientClasses={getGradientClasses(activeService === 'chatbot')}
                buttonGradient={getButtonGradient(activeService === 'chatbot')}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;